import React, { useEffect, useMemo, useRef, useState } from "react";
import PageTitle from "components/PageTitle";
import InfoApartmentCard from "./InfoApartmentCard";
import InfoParkingCard from "./InfoParkingCard";
import {
  usePickPremisesLinkMutation,
  usePickPremisesQuery,
  usePickPremisesSharedPageMutation,
} from "data/api/premises";
import { Apartment } from "../ApartmentList/dataTransformer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon"; 
import ButtonForm from "components/Button/ButtonForm";
import { Link } from "react-router-dom";
import { transformDataForApartmentInfo } from "./dataTransformer";
import CopyIcon from "components/constant/icons/CopyIcon";
import TelegramIcon from "components/constant/icons/TelegramIcon";
import WhatsAppIcon from "components/constant/icons/WhatsAppIcon";
import showToast from "utils/showToast";
import { Primise } from "enums/primisesType";
import { ResponseStatusType } from "data/api/exception";
import NotFound from "components/NotFound";
import { renderImage } from "utils/renderImage";
import { images } from "utils/resource";

interface Props {
  actions: boolean;
}

const ApartmentInfo = ({ actions }: Props) => {
  const location = useLocation();

  const { id } = useParams();

  const navigate = useNavigate();

  const [isOpenGenerateModal, setIsOpenGenerateModal] = useState(false);

  const copyAreaRef = useRef<HTMLSpanElement>(null);

  const [geratedLink, setGeneratedLink] = useState<string>("");

  const [data2, setData2] = useState<Apartment[] | null>(null);

  const idList = useMemo(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const ids = params.get("ids");

    const parsedIds = JSON.parse(ids ?? "[]");
    return Array.isArray(parsedIds) ? parsedIds : [];
  }, [location]);

  const { data, isLoading } = usePickPremisesQuery(idList);

  const [pickPrimisesLinkMutate, { isLoading: gerateLinkLoading }] =
    usePickPremisesLinkMutation();

  const [pickPrimisesForSharedUrl] = usePickPremisesSharedPageMutation();

  const hanldeRemove = (idToRemove: number) => {
    const newIds = idList.filter((id) => Number(id) !== idToRemove);
    const newParams = new URLSearchParams();
    newParams.append("ids", JSON.stringify(newIds));
    const newQueryString = newParams.toString();
    navigate(`${location.pathname}?${newQueryString}`, { replace: true });
  };

  const handleCopy = () => {
    if (copyAreaRef.current) {
      const textToCopy = copyAreaRef.current.textContent;
      if (textToCopy) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            showToast("Ссылка скопирована", "success", 1000);
          })
          .catch((err) => {
            showToast("Не удалось скопировать", "error", 1000);
          });
      }
    }
  };

  const shareViaTelegram = () => {
    const link = `${window.origin}/buildings/info/${geratedLink}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      link
    )}`;
    window.open(telegramUrl, "_blank");
  };

  const shareViaWhatsApp = () => {
    const link = `${window.origin}/buildings/info/${geratedLink}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(link)}`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    if (!actions) {
      init();
    }
  }, []);

  async function init() {
    if (id) {
      try {
        const { data: res } = await pickPrimisesForSharedUrl(id);
        if (res?.meta?.type === ResponseStatusType.SUCCESS && res) {
          setData2(res.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const list = (actions ? data?.data : data2) ?? [];

  function objEntries(data: { [apartmentType: string]: Apartment[] }) {
    return Object.entries(data);
  }

  const handleGenerateLink = async () => {
    try {
      const { data: res } = await pickPrimisesLinkMutate(idList);
      if (res?.meta?.type === ResponseStatusType.SUCCESS && res.data) {
        setGeneratedLink(res.data);
        setIsOpenGenerateModal(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="bg-bg-default">
      <div className="br-container py-[30px] ">
        <div
          className={`relative  ${actions ? "min-h-[50vh]" : "min-h-[100vh]"}`}
        >
          {(isLoading || gerateLinkLoading) && <Loader />}

          <div className="flex flex-col gap-[20px]">
            <PageTitle text="Выбранные объекты" className="mb-[20px]" />
            {list.length
              ? Object.entries(transformDataForApartmentInfo(list))?.map(
                  ([projectName, data]) => (
                    <div key={projectName} className="mt-[10px]">
                      <div className="flex items-center gap-[10px]">
                        <img
                          className="rounded-full h-[46px]"
                          width={46}
                          height={46}
                          crossOrigin="anonymous"
                          src={
                            renderImage(
                              objEntries(data)[0][1][0]?.project?.photo
                            ) || images.defaultProjectImg
                          }
                          alt=""
                        />
                        <h3 className="text-[20px] text-accent-default font-extrabold">
                          {projectName}
                        </h3>
                      </div>
                      <div className=" flex flex-col gap-[10px] mt-[20px]">
                        {data[Primise.APARTMENT]?.length ? (
                          <h3 className="text-[16px] text-accent-default font-semibold mt-4">
                            Квартиры
                          </h3>
                        ) : null}

                        <div className="flex gap-[20px] flex-wrap">
                          {data[Primise.APARTMENT]?.map(
                            (item: Apartment) =>
                              item.type === Primise.APARTMENT && (
                                <InfoApartmentCard
                                  actions={actions}
                                  hanldeRemove={hanldeRemove}
                                  key={item?.id}
                                  item={item}
                                />
                              )
                          )}
                        </div>
                        {data[Primise.PARKING]?.length ? (
                          <h3 className="text-[16px] text-accent-default font-semibold mt-4">
                            Паркинги
                          </h3>
                        ) : null}

                        <div className=" flex flex-col gap-[10px]">
                          {data[Primise.PARKING]?.map((item) => (
                            <InfoParkingCard
                              actions={actions}
                              hanldeRemove={hanldeRemove}
                              key={item?.id}
                              item={item}
                            />
                          ))}
                        </div>
                        {data[Primise.STOREROOM]?.length ? (
                          <h3 className="text-[16px] mt-4 text-accent-default font-semibold">
                            Кладовые
                          </h3>
                        ) : null}
                        <div className=" flex flex-col gap-[10px]">
                          {data[Primise.STOREROOM]?.map((item) => (
                            <InfoParkingCard
                              actions={actions}
                              hanldeRemove={hanldeRemove}
                              key={item?.id}
                              item={item}
                            />
                          ))}
                        </div>
                        {data[Primise.COMMERCIAL]?.length ? (
                          <h3 className="text-[16px] text-accent-default mt-4 font-semibold">
                            Коммерческие
                          </h3>
                        ) : null}

                        <div className=" flex flex-col gap-[10px]">
                          {data[Primise.COMMERCIAL]?.map((item) => (
                            <InfoParkingCard
                              actions={actions}
                              hanldeRemove={hanldeRemove}
                              key={item?.id}
                              item={item}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                )
              : !isLoading && <NotFound title="Нет выбранных объектов" />}
          </div>
        </div>
        {list.length && actions ? (
          <div className="flex flex-col-reverse sm:flex-row gap-[10px] mt-[25px] sm:w-[570px]">
            <ButtonForm
              className="border border-bg-accent-default py-[12px] w-full text-accent-default
  text-center flex justify-center flex-1"
              text={"Назад"}
              onClick={() => navigate(-1)}
            />
            <ButtonForm
              className="bg-accent-default py-[12px] w-full
 text-white-default text-center flex justify-center flex-1 hover:bg-accent-focus "
              text={"Сгенерировать подборку"}
              onClick={handleGenerateLink}
            />
          </div>
        ) : null}
      </div>

      <Modal
        className="w-full mx-auto items-center sm:w-[500px] rounded-t-[30px] md:rounded-[8px]"
        isOpen={isOpenGenerateModal}
      >
        <div className="max-h-[85vh] overflow-y-auto toolbar-scroll">
          <div className="text-accent-default mb-[20px] flex items-center justify-between">
            <div className="text-center flex flex-col gap-1">
              <h1 className="text-[25px] font-extrabold leading-[30px]">
                Где хотите поделиться?
              </h1>
              <p className="text-sm">
                Ниже представленны ссылки на вашу сгенерированную подборку
              </p>
            </div>

            <button onClick={() => setIsOpenGenerateModal(false)}>
              <CircleXIcon />
            </button>
          </div>
          <h2 className="text-sm font-semibold">Ссылки на проекты</h2>
          <div className="flex flex-col gap-[10px] mt-[10px]">
            <div className=" rounded-[4px] border border-boder-default text-accent-default p-4  flex gap-3 ">
              <Link
                to={`/buildings/info/${geratedLink}`}
                className="w-[70%] truncate"
              >
                <span ref={copyAreaRef}>
                  {`${window.origin}/buildings/info/${geratedLink}`}
                </span>
              </Link>
              <div onClick={handleCopy} className=" cursor-pointer">
                <CopyIcon />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-[10px] mt-[25px] mb-7">
            <ButtonForm
              className="bg-accent-default py-[12px] w-full
 text-white-default text-center flex justify-center flex-1 hover:bg-accent-focus "
              text={"Telegram"}
              onClick={shareViaTelegram}
              leftIcon={<TelegramIcon color="#FFFFFF" />}
            />
            <ButtonForm
              className="bg-accent-default py-[12px] w-full
 text-white-default text-center flex justify-center flex-1 hover:bg-accent-focus "
              text={"Whatsapp"}
              onClick={shareViaWhatsApp}
              leftIcon={<WhatsAppIcon />}
            />
            <ButtonForm
              className="bg-bg-default hover:bg-bg-focus py-[12px] w-full text-accent-default
  text-center flex justify-center"
              text={"Скопировать"}
              onClick={handleCopy}
              leftIcon={<CopyIcon />}
            />
          </div>
          <ButtonForm
            className="border border-bg-accent-default py-[12px] w-full text-accent-default
  text-center flex justify-center"
            text={"Назад"}
            onClick={() => setIsOpenGenerateModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ApartmentInfo;
