import React, { useEffect, useState } from "react";
import PageTitle from "components/PageTitle";
import ToggleSwitcher from "components/ToggleSwitcher";
import ButtonForm from "components/Button/ButtonForm";
import AddIconInCircle from "components/constant/icons/AddIconInCircle";
import { useWindowSize } from "hooks/useWindowSize";
import TableContainer from "components/Form/Table";
import TasksModals from "./TasksModals";
import { useGetOneTaskMutation, useGetTasksMutation } from "data/api/tasks";
import { objectToQueryString } from "utils/objectToQuery";
import useFormattedDate from "hooks/useFormatDate";
import { ResponseStatusType } from "data/api/exception";
import { TaskgetOneType } from "data/api/tasks/type";
import { PaginationType } from "modules/BaseClients";
import { ORDERBY } from "modules/Adminstration/AdminMobile";
import ArrowTableIcon from "components/constant/icons/Table/ArrowTableIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  handleOpenCreateTasks,
  handleOpenMortgageTask,
} from "store/TasksReducer";
import AnimationFrame from "components/AnimationFrame.tsx";

interface FilterData {
  page: number;
  limit: number;
  sort_by?: string;
  order_by?: string;
  is_archived: boolean;
}

const Tasks = () => {
  const { isMd, isSm } = useWindowSize();
  const [isTaskViewModalOpen, setTaskViewModalOpen] = useState(false);
  const [taskResultOpen, setTaskResultOpen] = useState(false);
  const [appliedModal, setAppliedModal] = useState(false);

  const selection = useSelector((state: RootState) => state.tasks);

  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState<ORDERBY>(ORDERBY.ASC);
  const [sortIsActive, setSortIsActive] = useState("");

  const [updatePage, setUpdatePage] = useState<boolean>(false);

  const [isSorted, setIsSorted] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState<
    PaginationType | undefined
  >({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });

  const [selectedTask, setSelectedTask] = useState<TaskgetOneType>();

  const formatDate = useFormattedDate();

  const [filter, setFilter] = useState<FilterData>({
    page: 1,
    limit: 10,
    is_archived: false,
  });

  const [tasksGetMutation, { isLoading }] = useGetTasksMutation();

  const [getOneTaskMutation, { isLoading: loadingGetOne }] =
    useGetOneTaskMutation();

  const [contentTable, setContentTable] = useState<any[]>([]);

  const [headerTable, setHeaderTable] = useState([
    {
      key: "task_type",
      title: "Тип задачи",
      sortable: true,
    },
    {
      key: "client_fullname",
      title: "Клиент",
      sortable: true,
    },
    {
      key: "end_date",
      title: "Дедлайн",
      sortable: true,
    },
    {
      key: "project_name",
      title: "Проект",
      sortable: true,
    },
  ]);

  useEffect(() => {
    loadList();
  }, [filter]);

  async function loadList() {
    const queryString = objectToQueryString({
      ...filter,
    });
    const { data: res } = await tasksGetMutation(queryString);

    if (!res || !res.data) return;

    setPaginationInfo(res.meta?.links);

    const updateList = res.data.map((item) => onChangeTableList(item));

    if (updatePage) {
      setUpdatePage(false);
      setContentTable((list) => [...list, ...updateList]);

      return;
    }

    setContentTable(updateList);
  }

  function toUpperCasefunc(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onChangeTableList = (item: any): any => {
    return {
      ...item,
      task_type: {
        key: item.task_type,
        text: (
          <div className="flex flex-col gap-[5px]">
            <span className=" text-blue-default text-[16px]">
              {toUpperCasefunc(item.task_type)}
            </span>
            <span className="text-sm">{item.comment}</span>
          </div>
        ),
      },
      client_fullname: item.client?.fullname,
      end_date: formatDate(item?.end_date, true),
      project_name: item.project?.name,
    };
  };

  const handleClickItem = async (item: any) => {
    try {
      setTaskViewModalOpen(true);
      const { data: res } = await getOneTaskMutation(item.id);
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        setSelectedTask(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCompleteTheTask = () => {
    setTaskViewModalOpen(false);
    setTaskResultOpen(true);
  };

  const handleApplyForMortgage = () => {
    dispatch(handleOpenMortgageTask(!selection.isOpenMortgageTaskModal));
  };

  const handleOpenCreateTaskModal = () => {
    dispatch(handleOpenCreateTasks(!selection.isOpenCreateModal));
  };

  function handleSortBy(sort_by: string, order_by: string) {
    setFilter({
      ...filter,
      page: 1,
      sort_by,
      order_by,
    });
  }

  const handleSortMobile = (value: string) => {
    setUpdatePage(false);

    if (sortIsActive !== value || !isSorted) {
      setSortIsActive(value);
      setIsSorted(true);
      setOrderBy(ORDERBY.ASC);
    } else {
      setOrderBy((prevOrder) =>
        prevOrder === ORDERBY.DESC ? ORDERBY.ASC : ORDERBY.DESC
      );
      setFilter((prev) => ({
        ...prev,
        page: 1,
        sort_by: value,
        order_by: orderBy === ORDERBY.DESC ? ORDERBY.ASC : ORDERBY.DESC,
      }));
    }
  };

  async function handleUpdateList() {
    setUpdatePage(true);
    const filterUpdate = {
      ...filter,
      page: filter.page + 1,
    };

    setFilter(filterUpdate);
  }

  return (
    <div className="bg-bg-default">
      <div className="br-container  py-5 min-h-[50vh]">
        <div className={`${!isSm && "flex justify-between items-center"}`}>
          {!isMd && <PageTitle text="Задачи" />}
          <div className=" flex items-center gap-[10px]">
            <div className="w-full">
              <ToggleSwitcher
                isToggle={filter.is_archived}
                setIsToggle={(value) => {
                  setUpdatePage(false);
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    is_archived: value,
                  }));
                }}
                label="Показать архив"
                className={`${!isSm && "min-w-[160px] mr-[20px]"} `}
              />
            </div>
            {!isMd && (
              <>
                {/* <ButtonForm
                  className={
                    "bg-white-default hidden lg:flex px-6 py-3 w-full hover:bg-accent-default hover:text-white-default justify-center text-accent-default text-ellipsis whitespace-nowrap"
                  }
                  text="Заявка на ипотеку"
                  onClick={() => handleApplyForMortgage()}
                /> */}
                <ButtonForm
                  className={
                    " px-6 py-3 w-full hidden lg:flex justify-center hover:opacity-50  bg-white-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
                  }
                  leftIcon={<AddIconInCircle />}
                  text="Добавить задачу"
                  onClick={() => handleOpenCreateTaskModal()}
                />
              </>
            )}
          </div>
        </div>
        <AnimationFrame>
          <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
            {isMd ? (
              <>
                <div className="flex items-center gap-[10px] no-scrollbar overflow-x-auto ">
                  {headerTable &&
                    headerTable.map((item, i) => (
                      <div
                        key={i}
                        onClick={() => handleSortMobile(item.key)}
                        className={`py-[3px] px-[12px] sm:px-[24px] lg:py-[11px] lg:px-6 flex items-center gap-2 rounded-[32px] text-[12px] font-normal flex-shrink-0  cursor-pointer ${
                          sortIsActive === item.key
                            ? "bg-accent-default text-white-default"
                            : "bg-white-default text-accent-default"
                        }`}
                      >
                        <p>{item.title}</p>

                        <div
                          className={`${
                            orderBy === ORDERBY.DESC &&
                            sortIsActive === item.key
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <ArrowTableIcon
                            color={
                              sortIsActive === item.key ? "#FFFFFF" : "#37465B"
                            }
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className={"bg-white-default rounded-lg mt-4"}>
                  {contentTable.map((item: any, index) => (
                    <div
                      key={`table-content-item-${index}`}
                      onClick={() => handleClickItem(item)}
                      className={`cursor-pointer w-full p-1 ${
                        index ? "border-t" : ""
                      } border-boder-default px-[15px] py-[12px]`}
                    >
                      <div className={"flex items-center mb-[5px]"}>
                        <p
                          className={
                            "text-sm font-medium mr-[12px] text-blue-default"
                          }
                        >
                          {toUpperCasefunc(item?.task_type?.key)}
                        </p>

                        <p className={"text-xs text-accent-default opacity-50"}>
                          {item?.end_date}
                        </p>
                      </div>

                      <div className={"mb-[15px]"}>
                        <p
                          className={
                            "text-xs max-w-[310px] overflow-hidden whitespace-nowrap text-ellipsis text-accent-default"
                          }
                        >
                          {item?.comment}
                        </p>
                      </div>

                      <div
                        className={
                          "flex justify-between text-sm font-medium text-accent-default"
                        }
                      >
                        <p>{item?.project?.name}</p>

                        <p className=" text-end">{item?.client_fullname}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <TableContainer
                // handleClickTableItem={handleClickTableItem}
                onLeftClickTrContent={handleClickItem}
                header={headerTable}
                content={contentTable}
                setData={setContentTable}
                loading={isLoading}
                handleSortBy={handleSortBy}
              />
            )}
            {paginationInfo
              ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                  <button
                    type={"button"}
                    className={
                      "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                    }
                    onClick={() => handleUpdateList()}
                  >
                    Загрузить еще
                  </button>
                )
              : null}
          </div>
        </AnimationFrame>
      </div>
      <TasksModals
        task={selectedTask}
        isTaskViewModalOpen={isTaskViewModalOpen}
        taskResultOpen={taskResultOpen}
        taskAddModalOpen={selection.isOpenCreateModal}
        mortgageModalOpen={selection.isOpenMortgageTaskModal}
        appliedModal={appliedModal}
        handleApplyForMortgage={handleApplyForMortgage}
        setTaskViewModalOpen={setTaskViewModalOpen}
        handleCompleteTheTask={handleCompleteTheTask}
        setTaskResultOpen={setTaskResultOpen}
        setTaskAddModalOpen={() => handleOpenCreateTaskModal()}
        setAppliedModal={setAppliedModal}
        isLoading={loadingGetOne}
        refetch={loadList}
      />
    </div>
  );
};

export default Tasks;
