import React from "react";

const LittleLikedIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.85481 2.6547C2.52991 1.97981 3.44542 1.60067 4.40001 1.60067C5.3546 1.60067 6.27011 1.97981 6.94521 2.6547L8.00001 3.7086L9.05481 2.6547C9.3869 2.31086 9.78414 2.03661 10.2234 1.84794C10.6626 1.65926 11.135 1.55995 11.613 1.5558C12.091 1.55165 12.565 1.64273 13.0074 1.82374C13.4499 2.00475 13.8518 2.27207 14.1898 2.61008C14.5278 2.94809 14.7952 3.35004 14.9762 3.79247C15.1572 4.23489 15.2483 4.70894 15.2441 5.18695C15.24 5.66495 15.1406 6.13734 14.952 6.57656C14.7633 7.01577 14.489 7.41301 14.1452 7.7451L8.00001 13.8912L1.85481 7.7451C1.17992 7.07 0.800781 6.15449 0.800781 5.1999C0.800781 4.24531 1.17992 3.3298 1.85481 2.6547Z"
        fill="#37465B"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LittleLikedIcon;
