import React from "react";

const HasTaskIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39941 7.40002H14.5994M7.39941 12.2H11.5994M20.5994 11C20.5994 12.3801 20.3082 13.6921 19.7839 14.878L20.6012 20.5991L15.6983 19.3734C14.3094 20.1545 12.7064 20.6 10.9994 20.6C5.69748 20.6 1.39941 16.302 1.39941 11C1.39941 5.69809 5.69748 1.40002 10.9994 1.40002C16.3013 1.40002 20.5994 5.69809 20.5994 11Z"
        stroke="#37465B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HasTaskIcon;
