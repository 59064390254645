import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "store";
import { getUserAction } from "store/UserReducer/actions";

import { matchRoutes } from "react-router-dom";
// import {
//   initializeFaro,
//   createReactRouterV6DataOptions,
//   ReactIntegration,
//   getWebInstrumentations,
// } from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

// initializeFaro({
//   url: "https://faro-collector-prod-ap-south-1.grafana.net/collect/8d481d1ca6b6abac02c8ce18f5a37c4a",
//   app: {
//     name: "brusnika",
//     version: "1.0.0",
//     environment: "production",
//   },
//   sessionTracking: {
//     samplingRate: 1,
//     persistent: true,
//   },
//   instrumentations: [
//     ...getWebInstrumentations(),

//     new TracingInstrumentation(),

//     new ReactIntegration({
//       router: createReactRouterV6DataOptions({
//         matchRoutes,
//       }),
//     }),
//   ],
// });

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-[67px] lg:pt-0 bg-bg-default">
      <Outlet />
    </div>
  );
}

export default App;
